import style from '../styles/pages/ContactMe.module.scss'

function ContactMe () {
  return (
    <>
      <h1 className={style['contact-me']}>Contact me section</h1>
      <a href='https://project1.abimaelguerrero.com/'>Click me if you want to se the first project</a>
    </>
  )
}

export default ContactMe